import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as TrainingActions from "../actions/trainingAction";
import { createTrainingJob, fetchTrainingJobDetails } from "src/api/ml-training";
import { showErrorMessage } from "./shared";
import { CreateTrainingJobRequest, TrainingJobInfo } from "src/models/Training";

function* createTrainingJobSaga(action: ReturnType<typeof TrainingActions.createTrainingJob.request>) {
    try {
      const request: CreateTrainingJobRequest = action.payload;
      const trainingJobId: string = yield call(createTrainingJob, request);
      const jobDetails: TrainingJobInfo = yield call(fetchTrainingJobDetails, trainingJobId);
      yield put(TrainingActions.createTrainingJob.success(jobDetails));
    } catch (error : any) {
      showErrorMessage(error);
      yield put(TrainingActions.createTrainingJob.failure(error.message));
    }
  }
  

function* fetchTrainingJobDetailsSaga(action: ReturnType<typeof TrainingActions.fetchTrainingJobDetails.request>) {
  try {
    const trainingJobId: string = action.payload;
    const jobDetails: TrainingJobInfo = yield call(fetchTrainingJobDetails, trainingJobId);
    yield put(TrainingActions.fetchTrainingJobDetails.success(jobDetails));
  } catch (error : any) {
    showErrorMessage(error);
    yield put(TrainingActions.fetchTrainingJobDetails.failure(error.message));
  }
}

// Watchers
function* watchCreateTrainingJob() {
  yield takeEvery(TrainingActions.createTrainingJob.request, createTrainingJobSaga);
}

function* watchFetchTrainingJobDetails() {
  yield takeEvery(TrainingActions.fetchTrainingJobDetails.request, fetchTrainingJobDetailsSaga);
}

// Root saga
export default function* trainingSaga() {
  yield all([
    fork(watchCreateTrainingJob),
    fork(watchFetchTrainingJobDetails),
  ]);
}