import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as UploadAction from "../actions/dataset";
import { 
  initiateMultipartUpload, uploadParts, completeMultipartUpload 
} from "../../api/mlUpload";
import {
  createDataset,
  fetchDatasetInfo as fetchDatasetIdsApi,
  fetchDatasetDetails as fetchDatasetDetailsApi,
  updateImageLabelsApi,
  fetchImagesFromS3
} from "../../api/ml-dataset";
import { getRejectionMapping } from "../../api/grain";
import { showErrorMessage } from "./shared";
import { IRejectionMapping, Image, DatasetInfo, Dataset, CreateDatasetRequest } from "src/models/UploadImages";
import { LabelingStatus } from "src/models/LabelingStatus";

// Selectors
import { IStore } from "../reducers";
import { IImageWithLabel, IUploadJobImageRequest } from "src/models/UploadImages";


function* fetchRejectionMapping(grainId: string): Generator<any, IRejectionMapping, any> {
  const rejectionMappingFromApi: IRejectionMapping = yield call(getRejectionMapping, grainId);
  const rejectionMapping: IRejectionMapping = { good: "Good Grains", na: "NA", ...rejectionMappingFromApi };
  yield put(UploadAction.setRejectionMapping(rejectionMapping));
  return rejectionMapping;
}


export function* fetchDatasetInfo(): Generator<any, void, any> {
  try {
    const grainId: string = yield select((state: IStore) => state.grainType.grainId);
    const rejectionMapping: IRejectionMapping = yield call(fetchRejectionMapping, grainId);
    const datasets: DatasetInfo[] = yield call(fetchDatasetIdsApi);
    yield put(UploadAction.setRejectionMapping(rejectionMapping));
    yield put(UploadAction.fetchDatasetInfo.success(datasets));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.fetchDatasetInfo.failure(error));
  }
}


function* fetchDatasetDetails(action: ReturnType<typeof UploadAction.fetchDatasetDetails.request>): Generator<any, void, any> {
  try {
    const { datasetId } = action.payload;
    const datasetDetails: { imageList: Image[] } = yield call(fetchDatasetDetailsApi, datasetId);
    const grainId: string = yield select((state: IStore) => state.grainType.grainId);
    const rejectionMapping: IRejectionMapping = yield call(fetchRejectionMapping, grainId);
    const s3ImageResponse = yield call(fetchImagesFromS3, datasetId);
    const s3ImageUrls: string[] = s3ImageResponse.imageUrls;

    const combinedImages: IImageWithLabel[] = datasetDetails.imageList.map(img => {
      const matchedUrl = s3ImageUrls.find(url => {
        const decodedUrl = decodeURIComponent(url);
        const urlPath = decodedUrl.split("?")[0];
        return urlPath.includes(img.imagePath);
      });

      return {
        ...img,
        imageUrl: matchedUrl ? matchedUrl : null,
      };
    });

    yield put(
      UploadAction.fetchDatasetDetails.success({
        ...datasetDetails,
        s3ImageUrls,
        combinedImages,
        rejectionMapping,
      })
    );
  } catch (error: any) {
    yield put(UploadAction.fetchDatasetDetails.failure(error));
  }
}


function* updateImageLabels(action: ReturnType<typeof UploadAction.updateImageLabels.request>): Generator<any, void, any> {
  try {
    const { datasetId, changedLabels, status, grainId } = action.payload;
    yield call(updateImageLabelsApi, datasetId, changedLabels, status, grainId);
    yield put(UploadAction.updateImageLabels.success({ changedLabels, status, grainId }));
  } catch (error: any) {
    yield put(UploadAction.updateImageLabels.failure(error));
  }
}

export function* saveAsDraft(action: ReturnType<typeof UploadAction.saveAsDraft.request>): Generator<any, void, any> {
  try {
    const { datasetId, changedLabels, status, grainId } = action.payload;
    yield call(updateImageLabels, { payload: { datasetId, changedLabels, status, grainId } });
    yield put(UploadAction.saveAsDraft.success());
    yield put(UploadAction.updateLabelingStatus(LabelingStatus.IN_PROGRESS));
  } catch (error : any){
    yield put(UploadAction.saveAsDraft.failure(error));
  }
}

export function* completeLabeling(action: ReturnType<typeof UploadAction.completeLabeling.request>): Generator<any, void, any> {
  try {
    const { datasetId, changedLabels, status, grainId } = action.payload;
    yield call(updateImageLabels, { payload: { datasetId, changedLabels, status, grainId } });
    yield put(UploadAction.completeLabeling.success());
    yield put(UploadAction.updateLabelingStatus(LabelingStatus.COMPLETED));
  } catch (error: any) {
    yield put(UploadAction.completeLabeling.failure(error));
  }
}

export function* upload(action: ReturnType<typeof UploadAction.upload.request>): Generator<any, string | void, any> {
  try {
    const { files, datasetId }: IUploadJobImageRequest = action.payload;

    if (datasetId.startsWith('dataset_')) {
      yield put(UploadAction.setUploadProgress(0));
      const grainId: string = yield select((state: IStore) => state.grainType.grainId);
      const uploadPath = `ml-images/${grainId}/${datasetId}`;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const key = `${uploadPath}/${file.name}`;
        const { uploadId, presignedUrls } = yield call(initiateMultipartUpload, key, file.type);
        const parts = yield call(uploadParts, presignedUrls, file);
        yield call(completeMultipartUpload, key, uploadId, parts);

        yield put(UploadAction.setUploadProgress(Math.round(((i + 1) / files.length) * 100)));
      }
    }

    yield call(uploadComplete);
    yield put(UploadAction.upload.success("Upload successful"));
    return "Upload successful";
  } catch (error: any) {
    console.error("Upload failed:", error);
    showErrorMessage(error);
    yield put(UploadAction.upload.failure(error.message));
    throw error;
  }
}

export function* uploadComplete(): Generator<any, void, any> {
  try {
    const images: IImageWithLabel[] = yield select((state: IStore) => state.dataset.images);
    const datasetId: string = yield select((state: IStore) => state.dataset.datasetID);
    const grainId: string = yield select((state: IStore) => state.grainType.grainId);
    const username: string = yield select((state: IStore) => state.user?.userProfile.response?.username ?? '');
    const datasets: Dataset[] = yield select((state: IStore) => state.dataset.datasets);

    const isExistingDataset = datasets.some((dataset: Dataset) => dataset.datasetID === datasetId);
    yield put(UploadAction.setIsExistingDataset(isExistingDataset));

    if (!images || !Array.isArray(images) || images.length === 0) {
      throw new Error('Images array is empty or invalid.');
    }

    if (!isExistingDataset) {
      const request: CreateDatasetRequest = {
        datasetID:datasetId,
        imageList : images,
        grainId,
        status: "NOT_LABELED",
        username
      };

      const datasetResponse = yield call(createDataset, request);
      const jobId = datasetResponse.datasetID;
      const updatedImages = datasetResponse.images;

      yield put(UploadAction.setDatasetId(jobId));
      yield put(UploadAction.setImages(updatedImages));
    }
  } catch (error: any) {
    showErrorMessage(error);
  }
}

export function* initializeLabeling(action: ReturnType<typeof UploadAction.initializeLabeling.request>): Generator<any, void, any> {
  try {
    const datasetId = `dataset_${Date.now()}`;
    yield put(UploadAction.setDatasetId(datasetId));
    yield put(UploadAction.setUploadProgress(0));
    yield put(UploadAction.initializeLabeling.success());
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.initializeLabeling.failure(error));
  }
}

// Watchers
export function* watchUpload() {
  yield takeEvery(UploadAction.upload.request, upload);
}

export function* watchInitializeLabeling() {
  yield takeEvery(UploadAction.initializeLabeling.request, initializeLabeling);
}

export function* watchCompleteLabeling() {
  yield takeEvery(UploadAction.completeLabeling.request, completeLabeling);
}

export function* watchFetchDatasetDetails() {
  yield takeEvery(UploadAction.fetchDatasetDetails.request, fetchDatasetDetails);
}

export function* watchSaveAsDraft() {
  yield takeEvery(UploadAction.saveAsDraft.request, saveAsDraft);
}

export function* watchFetchDatasetInfo() {
  yield takeEvery(UploadAction.fetchDatasetInfo.request, fetchDatasetInfo);
}

export function* watchUpdateImageLabels() {
  yield takeEvery(UploadAction.updateImageLabels.request, updateImageLabels);
}

export default function* rootSaga() {
  yield all([
    fork(watchUpload),
    fork(watchInitializeLabeling),
    fork(watchCompleteLabeling),
    fork(watchFetchDatasetDetails),
    fork(watchSaveAsDraft),
    fork(watchFetchDatasetInfo),
    fork(watchUpdateImageLabels)
  ]);
}
