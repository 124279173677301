import { Box, Button, HStack, Image, Pressable, Switch, useToast, Text, VStack, Modal, Input } from 'native-base';
import * as React from 'react';
import { launchImageLibrary } from 'react-native-image-picker';
import { Auth as Authorization } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import * as AppActions from '../../redux/actions/appAction';
import * as UploadAction from '../../redux/actions/uploadAction';
import * as BluetoothActions from '../../redux/actions/bluetoothAction';
import { IStore } from 'src/redux/reducers';
import { IS_TEST_ENV, S3_BUCKET_URL } from 'src/constants';
import { containerStyle } from 'src/styles/containerStyle';
import { textStyle } from 'src/styles/textStyle';
import { getText } from 'src/utils/i18n';
import { sendAccountDeletionEmail } from 'src/utils/emailUtils';
import { inputStyle } from 'src/styles/inputStyle';
import { isNative } from 'src/utils/utils';
import LanguageDropdown from '../../components/dropdown/LanguageDropdown';

export default function SettingsPage({ navigation }) {
    const dispatch = useDispatch();
    const toast = useToast();
    const username = useSelector((store: IStore) => store.user.userProfile.response?.username);
    const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);
    const updateLogoResponse = useSelector((store: IStore) => store.user.updateUserLogo.response);
    const userSettings = useSelector((store: IStore) => store.user.userProfile.response?.settings);
    const colorMode = useSelector((store: IStore) => store.app.colorMode);

    const [selectedImage, setSelectedImage] = React.useState<string | undefined>(undefined);
    const [isUploadLogsLoading, setIsUploadLogsLoading] = React.useState<boolean>(false);

    const [deletionReason, setDeletionReason] = React.useState<string>("");
    const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] = React.useState<boolean>(false);
    const [isRequestingDeletion, setIsRequestingDeletion] = React.useState<boolean>(false);

    const rpiConnected = useSelector((store: IStore) => store.bluetooth.connectedRpi.connectedDevice);

    React.useEffect(() => {
        if (updateLogoResponse) {
            toast.show({
                title:getText('logoUpdated'),
                placement: 'bottom',
            })
        }
    }, [updateLogoResponse])

    React.useEffect(() => {
        if (userSettings) {
            setSelectedImage(`${S3_BUCKET_URL()}/${userSettings?.logo}` || "")
        }
    }, [userSettings])

    const checkAuthAndUpload = async (data: any) => {
        Authorization.currentUserInfo().then(async (user) => {
            setSelectedImage(data?.assets[0].uri)
            dispatch(UploadAction.uploadLogo.request({
                type: 'user',
                username: username,
                isBase64: false,
                orgId: orgId,
                dataObject: data?.assets[0]
            }));

        }).catch((e) => {
            console.log('force logout')
            dispatch(AppActions.forceLogout());
            console.log('error occurred', e)
        });
    }

    const onEditLogoClicked = () => {
        launchImageLibrary({
            selectionLimit: 0,
            mediaType: 'photo',
            includeBase64: false,
        }, checkAuthAndUpload)
    }

    const uploadIotLogs = async () => {
        setIsUploadLogsLoading(true)
        setTimeout(() => {
            setIsUploadLogsLoading(false)
        }, 3 * 1000)
        dispatch(BluetoothActions.uploadBleLogs.request({
            username: username,
            isTest: IS_TEST_ENV() || false
        }));
    }

    return (
        <>
            <Modal
                size={"lg"}
                isOpen={isDeleteAccountModalVisible}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>{getText('deleteAccount')}</Modal.Header>
                    <Modal.Body>
                        <VStack p="2" space={4}>
                            <Text>{getText('deletionWarning')}
                            </Text>

                            <Input
                                key={"deletionReason"}
                                style={inputStyle.measurementInput}
                                placeholder={getText('deletionReason')}
                                onChangeText={(text) => {
                                    setDeletionReason(text)
                                }}
                                value={deletionReason} />
                        </VStack>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                                setIsDeleteAccountModalVisible(false)
                            }}>
                                {getText('cancel')}
                            </Button>
                            <Button
                                onPress={async () => {
                                    setIsDeleteAccountModalVisible(false)
                                    await sendAccountDeletionEmail(username, deletionReason)
                                }
                                }>{getText('reqAccDeletion')}</Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <Box variant={"main"} style={containerStyle.mainContainer}>
                <VStack p="2" space={4}>
                    <Pressable onPress={() => {
                        onEditLogoClicked()
                    }}>
                        <Image
                            style={{
                                width: 100,
                                height: 100,
                                borderRadius: 8,
                                alignSelf: "center",
                            }}
                            source={{
                                uri: selectedImage || "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
                            }} alt="Alternate Text" size="xl" />
                    </Pressable>

                    <Button
                        style={{ marginTop: 24 }}
                        onPress={() => onEditLogoClicked()}>{getText('updateLogo')}</Button>

                    <Text style={textStyle.subSectionText}>{getText('account')}</Text>
                    <Button
                        onPress={() => {
                            navigation.navigate('ChangePasswordPage')
                        }}>{getText('changePassword')}</Button>

                    <Button
                        isLoading={isRequestingDeletion}
                        isLoadingText="Requesting deletion..."
                        onPress={async () => {
                            setIsDeleteAccountModalVisible(true)
                        }}>{getText('reqAccDeletion')}</Button>

                    <Text>{getText('changeLanguage')}</Text>
                    <LanguageDropdown />
                    <HStack
                        style={{
                            justifyContent: "space-between",
                            marginTop: 12
                        }}
                        alignItems="center" space={4}>
                        <Text style={textStyle.subSectionText}>{getText('darkMode')}</Text>
                        <Switch onToggle={() => {
                            dispatch(AppActions.setAppColorMode(colorMode === "dark" ? "light" : "dark"))
                        }} isChecked={colorMode === "dark"} size="md" />
                    </HStack>

                    {
                        (isNative()) &&
                            <>
                                <Text style={textStyle.subSectionText}>{getText('debugging')}</Text>
                                <Button
                                    isLoading={isUploadLogsLoading}
                                    isDisabled={rpiConnected === undefined}
                                    isLoadingText="Sharing IoT Logs..."
                                    onPress={async () => {
                                        await uploadIotLogs()
                                    }}>{getText('shareLogs')}</Button>
                            </>
                    }
                </VStack>
            </Box>
        </>
    );
}