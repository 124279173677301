import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions/dataset";
import {
  IUploadFileState,
  IImageWithLabel,
  IRejectionMapping,
} from "src/models/UploadImages";
import { LabelingStatus } from "src/models/LabelingStatus";

export interface IDatasetState {
  readonly upload: IUploadFileState;
  readonly datasetID: string | null;
  readonly rejectionMapping: IRejectionMapping;
  readonly images: IImageWithLabel[];
  readonly uploadProgress: number;
  readonly datasets: {
    createdAt: any;
    grainId: string;
    username: any;
    datasetID: string; 
    status: string,
  }[];
  readonly selectedDatasetId: string | null;
  readonly labelingStatus: LabelingStatus;
  readonly uploadCompleted: boolean;
  readonly updateImageLabel: { imagePath: string, label: string };
  readonly loading: boolean;
  readonly error?: any;
  readonly isExistingDataset?: boolean;
  readonly jobDetails?: any;
  readonly isJob: boolean; 
  readonly id: string;
}

export const initialDatasetState: IDatasetState = {
  upload: {
    error: undefined,
    isFetching: false,
    success: false,
    s3Path: undefined,
  },
  datasetID: null,
  rejectionMapping: {},
  images: [],
  uploadProgress: 0,
  datasets: [],
  selectedDatasetId: null,
  labelingStatus: LabelingStatus.NOT_STARTED,
  uploadCompleted: false,
  updateImageLabel: { imagePath: '', label: '' },
  loading: false,
  isExistingDataset: false,
  jobDetails: null,
  isJob: false,
  id: ''
};

export const DatasetReducer = (
  state: IDatasetState = initialDatasetState,
  action: ActionType<typeof actions>
): IDatasetState => {
  switch (action.type) {
    case getType(actions.upload.request):
      return { ...state, upload: { ...state.upload, isFetching: true }, loading: true, uploadCompleted: false };

    case getType(actions.upload.success):
      return { ...state, uploadProgress: 100, loading: false, uploadCompleted: true };

    case getType(actions.upload.failure):
      return { ...state, upload: { ...state.upload, isFetching: false, error: action.payload }, loading: false };

    case getType(actions.setDatasetId):
      return { ...state, datasetID: action.payload };

    case getType(actions.setRejectionMapping):
      return { ...state, rejectionMapping: action.payload };
    
    case getType(actions.setIsExistingDataset):
      return { ...state, isExistingDataset: action.payload };

    case getType(actions.setImages):
      return { ...state, images: action.payload };

    case getType(actions.clearDataset):
      return { ...state, datasetID: null, images: []};

    case getType(actions.updateImageLabels.request):
      return { ...state, loading: true };
    
    case getType(actions.updateImageLabels.success):
      return {
        ...state,
        images: state.images.map((img) => ({
          ...img,
          label: action.payload.changedLabels[img.imagePath] || img.label,
        })),
        labelingStatus: action.payload.status,
        loading: false,
      };

    case getType(actions.updateImageLabels.failure):
      return { ...state, loading: false, error: action.payload };

    case getType(actions.fetchDatasetDetails.request):
      return { ...state, loading: true };

    case getType(actions.fetchDatasetDetails.success):
      const { combinedImages, ...rest } = action.payload;
      return {
        ...state,
        ...rest,
        images: combinedImages,
        loading: false,
        error: undefined
      };

    case getType(actions.fetchDatasetDetails.failure):
      return { ...state, loading: false, error: action.payload };

    case getType(actions.clearJobDetails):
      return { ...state, jobDetails: null };

    case getType(actions.uploadComplete):
      return { ...state, uploadCompleted: true, loading: false };

    case getType(actions.setUploadProgress):
      return { ...state, uploadProgress: action.payload };

    case getType(actions.completeLabeling.request):
      return { ...state, loading: true };

    case getType(actions.completeLabeling.success):
      return { ...state, labelingStatus: LabelingStatus.COMPLETED, loading: false };

    case getType(actions.completeLabeling.failure):
      return { ...state, loading: false, error: action.payload };

    case getType(actions.initializeLabeling.request):
      return { ...state, loading: true };

    case getType(actions.initializeLabeling.success):
      return { ...state, labelingStatus: LabelingStatus.NOT_STARTED, loading: false };

    case getType(actions.initializeLabeling.failure):
      return { ...state, loading: false, error: action.payload };

    case getType(actions.fetchDatasetInfo.request):
        // console.log('fetchDatasetInfo.request');
      return { ...state, loading: true };

    case getType(actions.fetchDatasetInfo.success):
      return { 
        ...state, 
        datasets: action.payload.map((dataset: any) => ({
          createdAt: dataset.createdAt || null,
          grainId: dataset.grainId || '',
          username: dataset.username || '',
          datasetID: dataset.datasetID,
          status: dataset.status
        })), 
        loading: false 
      };

    case getType(actions.fetchDatasetInfo.failure):
      return { ...state, error: action.payload, loading: false };
    
    case getType(actions.setIsJob):
      return { ...state, isJob: action.payload };

    case getType(actions.setId):
      return { ...state, id: action.payload };

    case getType(actions.saveAsDraft.request):
      return { ...state, loading: true };

    case getType(actions.saveAsDraft.success):
      return { ...state, loading: false };

    case getType(actions.saveAsDraft.failure):
      return { ...state, loading: false, error: action.payload };
    
    case getType(actions.getJobDetails.request):
      return { ...state, loading: true };

    case getType(actions.getJobDetails.success):
      return { ...state, jobDetails: action.payload, loading: false };

    case getType(actions.getJobDetails.failure):
      return { ...state, error: action.payload, loading: false };

    case getType(actions.reset):
      return initialDatasetState;

    default:
      return state;
  }
};