import { createAction, createAsyncAction } from "typesafe-actions";
import { CreateTrainingJobRequest, TrainingJobInfo } from 'src/models/Training';

export const reset = createAction('RESET')<void>();
export const createTrainingJob = createAsyncAction(
    'CREATE_TRAINING_JOB_REQUEST',
    'CREATE_TRAINING_JOB_SUCCESS',
    'CREATE_TRAINING_JOB_FAILURE',
)<CreateTrainingJobRequest, TrainingJobInfo, string>();

export const fetchTrainingJobDetails = createAsyncAction(
    'FETCH_TRAINING_JOB_DETAILS_REQUEST',
    'FETCH_TRAINING_JOB_DETAILS_SUCCESS',
    'FETCH_TRAINING_JOB_DETAILS_FAILURE',
    )<string, TrainingJobInfo, string>();