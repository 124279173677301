import * as Effects from "redux-saga/effects";
import * as GrainTypeAction from "../actions/grainTypeAction";
import * as GrainProfileAction from "../actions/grainProfileAction";
import * as AppAction from "../actions/appAction";
import * as JobAction from "../actions/jobAction";
import * as NotificationAction from "../actions/notificationAction";
import * as BluetoothAction from "../actions/bluetoothAction";
import * as ReportAction from "../actions/reportAction";
import * as UploadAction from "../actions/uploadAction";
import * as UserAction from "../actions/userAction";
import * as OrgAction from "../actions/orgAction";
import * as userGuideActions from "../actions/userGuideActions";

import { updateLocale } from "../actions/appAction";
import { call, takeLatest, select} from 'redux-saga/effects';
import { setLocale, setInitialLocale } from '../../utils/i18n';
import { IAppActionState } from '../reducers/appReducer';

const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;

const getCurrentLocale = (state: { app: IAppActionState }) => state.app.locale;

function* handleLocaleChange(action: ReturnType<typeof updateLocale>) {
  try {
    const { payload: newLocale } = action;
    yield call(setLocale, newLocale);
  } catch (error) {
    console.error('Failed to change locale:', error);
  }
}

function* initializeLocale() {
  try {
    const initialLocale: string = yield call(setInitialLocale);
    console.log('initialLocale', initialLocale);

    const currentLocale: string = yield select(getCurrentLocale);
    console.log('currentLocale', currentLocale);

    if (initialLocale !== currentLocale) {
      console.log('Dispatching updateLocale action');
      yield put(updateLocale(initialLocale));
    }
  } catch (error) {
    console.error('Failed to initialize locale:', error);
  }
}


export function* resetFilters(action: ReturnType<typeof AppAction.resetFilters>): any {
  try {
    yield put(AppAction.setGrainIdFilter('all'));
    yield put(AppAction.setDateRange(undefined));
    yield put(AppAction.setSearchQuery(undefined));
    yield put(AppAction.setCenterIdFilter('all'));
    yield put(AppAction.setDistrictFilter('all'));
    yield put(AppAction.setStateFilter('all'));
    yield put(AppAction.updateLocale('en'));
  } catch (error: any) {
  }
}

export function* logout(action: ReturnType<typeof AppAction.logout>): any {
  try {
    yield put(GrainTypeAction.reset());
    yield put(GrainProfileAction.reset());
    yield put(OrgAction.reset());
    yield put(JobAction.reset());
    yield put(NotificationAction.reset());
    yield put(BluetoothAction.reset());
    yield put(ReportAction.reset());
    yield put(UploadAction.reset());
    yield put(UserAction.reset());
    yield put(userGuideActions.resetUserGuide());
    yield put(AppAction.reset());
  } catch (error: any) {
    
  }
}

export function* forceLogout(action: ReturnType<typeof AppAction.forceLogout>): any {
  try {
  } catch (error: any) {

  }
}

/*
 * WATCHERS
 */
function* watchLocaleChange() {
  yield takeLatest(updateLocale, handleLocaleChange);
}

export function* watchResetFilters() {
  yield takeEvery(AppAction.resetFilters, resetFilters);
}

export function* watchLogout() {
  yield takeEvery(AppAction.logout, logout);
}

export function* watchForceLogout() {
  yield takeEvery(AppAction.forceLogout, forceLogout);
}

export default function* root() {
  yield all([fork(watchResetFilters), fork(watchLogout), fork(watchForceLogout), fork(initializeLocale), fork(watchLocaleChange)]);
}