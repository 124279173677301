import * as React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import { containerStyle } from 'src/styles/containerStyle';
import { Box } from 'native-base';
import { store } from 'src/redux/store';
import MlStatusBar from 'src/components/ml/MlStatusBar';
import MlStackTabs from 'src/components/ml/MlStackTabs';

export default function MlDashboardPage({ navigation }) {
    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <MlStatusBar/>
            <MlStackTabs navigation={navigation} />
        </Box>
    );
}