import React, { useEffect } from 'react';
import { Box, Text, FlatList, Image, Pressable } from 'native-base';
import { Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import { getFaqItems } from 'src/redux/actions/userGuideActions';
import { containerStyle } from 'src/styles/containerStyle';
import { FaqItem } from 'src/models/FaqItem';
import { textStyle } from 'src/styles/textStyle';

const UserGuidePage = () => {
  const dispatch = useDispatch();
  const { items, isFetching, error } = useSelector((state: IStore) => state.userGuide.faqItems);
  const orgId = useSelector((state: IStore) => state.user.userProfile.response?.org.orgId);

  useEffect(() => {
    if (orgId) {
      dispatch(getFaqItems.request(orgId.toString()));
    }
  }, [orgId]);

  const openLink = (url: string) => {
    Linking.openURL(url);
  };

  const renderItem: (props: { item: FaqItem }) => JSX.Element = ({ item }) => (
    <Box variant={"main"}>
      <Pressable onPress={() => openLink(item.linkUrl)} style={containerStyle.itemContainer}>
        <Box style={containerStyle.thumbnailContainer}>
          <Image
            source={item.thumbnailUrl ? { uri: item.thumbnailUrl } : { uri: "https://grams-images-test-bucket.s3.us-west-2.amazonaws.com/grams/thumbnail.jpg" }}
            style={containerStyle.thumbnail}
            alt={item.title}
          />
        </Box>
        <Box style={containerStyle.textContainer}>
          <Text style={textStyle.title}>{item.title}</Text>
          <Text style={textStyle.description}>{item.description}</Text>
        </Box>
      </Pressable>
    </Box>

  );

  if (isFetching) {
    return (
      <Box variant="main">
        <Text>Loading...</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Box variant="main">
        <Text>Error fetching FAQ items: {error.message}</Text>
      </Box>
    );
  }

  if (items.length > 0) {
    return (
      <Box variant="main">
        <FlatList
          data={items}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          style={containerStyle.listContainer}
        />
      </Box>
    );
  }

  return (
    <Box variant="main">
      <Text>No FAQ items found.</Text>
    </Box>
  );
};

export default UserGuidePage;
