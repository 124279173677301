import * as Effects from 'redux-saga/effects';
import * as UserGuideActions from '../actions/userGuideActions';
import * as api from '../../api/userGuide';
import { showErrorMessage } from './shared';
import { IStore } from '../reducers';
import { getCacheExpiryDuration } from '../../utils/remoteConfig';

const call: any = Effects.call;
const put: any = Effects.put;
const select: any = Effects.select;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;


const getFaqItemsExpiry = (store: IStore) => store.userGuide.faqItems.expiry;
const getCachedFaqItems = (store: IStore) => store.userGuide.faqItems.items;

export function* getFaqItems(action: ReturnType<typeof UserGuideActions.getFaqItems.request>): any {
  try {
    const expiry = yield select(getFaqItemsExpiry);
    let expiryDate = expiry === undefined ? undefined : new Date(expiry);
    const currentDate = new Date();
    const cachedFaqItems = yield select(getCachedFaqItems);
    if (cachedFaqItems.length > 0 && expiryDate && expiryDate.getTime() > currentDate.getTime()) {
      console.log("Using cached FAQ items");
      yield put(UserGuideActions.getFaqItems.success({
        cached: true,
        items: cachedFaqItems,
        expiry: expiryDate
      }));
    } else {
      console.log("Fetching new FAQ items");
      const response = yield call(api.getFaqItems, action.payload);
      let expiry = new Date();
      expiry.setSeconds(expiry.getSeconds() + getCacheExpiryDuration());

      yield put(UserGuideActions.getFaqItems.success({
        cached: false,
        items: response,
        expiry: expiry
      }));

    }
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UserGuideActions.getFaqItems.failure(error));
  }
}

/*
 * WATCHERS
 */
export function* watchGetFaqItems() {
  yield takeEvery(UserGuideActions.getFaqItems.request, getFaqItems);
}

export default function* root() {
  yield all([fork(watchGetFaqItems)]);
}