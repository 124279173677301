import { ActionType, getType } from "typesafe-actions";
import * as TrainingActions from '../actions/trainingAction';
import { TrainingJobInfo } from 'src/models/Training';

interface TrainingState {
  trainingJobs: TrainingJobInfo[];
  currentTrainingJob: TrainingJobInfo | null;
  loading: boolean;
  error: string | null;
}

const initialState: TrainingState = {
  trainingJobs: [],
  currentTrainingJob: null,
  loading: false,
  error: null,
};

export const trainingReducer = (
    state: TrainingState = initialState,
    action: ActionType<typeof TrainingActions>
): TrainingState => {
    switch (action.type) {
        case getType(TrainingActions.createTrainingJob.request):
            return {
                ...state,
                loading: true,
                error: null,
            };
        case getType(TrainingActions.createTrainingJob.success):
            return {
                ...state,
                loading: false,
                trainingJobs: [...state.trainingJobs, action.payload],};
        case getType(TrainingActions.createTrainingJob.failure):
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case getType(TrainingActions.fetchTrainingJobDetails.request):
            return {
                ...state,
                loading: true,
                error: null,
            };
        case getType(TrainingActions.fetchTrainingJobDetails.success):
            return {
                ...state,
                loading: false,
                currentTrainingJob: action.payload,
            };
        case getType(TrainingActions.fetchTrainingJobDetails.failure):
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            
        case getType(TrainingActions.reset):
            return initialState;
        default:
            return state;
        }
    };