import { createAction, createAsyncAction } from "typesafe-actions";
import { 
  IUploadJobImageRequest, 
  IRejectionMapping,
  IImageWithLabel,
  FetchDatasetDetailsRequest,
  UpdateImageLabelsRequest,
  UpdateImageLabelsSuccess,
  UpdateImageLabelsFailure,
  onSubmitLabelingRequest,
  onSubmitLabelingFailure
} from "../../models/UploadImages";
import { LabelingStatus } from "../../models/LabelingStatus";

export const reset = createAction('RESET')<void>();

export const upload = createAsyncAction(
  "upload/PRESIGNED_URL_REQUEST",
  "upload/PRESIGNED_URL_SUCCESS",
  "upload/PRESIGNED_URL_FAILURE"
)<IUploadJobImageRequest, string, Error>();
export const clearJobDetails = createAction('CLEAR_JOB_DETAILS')();
export const setDatasetId = createAction('SET_DATASET_ID')<string>();
export const setRejectionMapping = createAction('SET_REJECTION_MAPPING')<IRejectionMapping>();
export const setImages = createAction('SET_IMAGES')<IImageWithLabel[]>();
export const setUploadProgress = createAction('SET_UPLOAD_PROGRESS')<number>();
export const uploadComplete = createAction('UPLOAD_COMPLETE')<void>();
export const setIsJob = createAction('SET_IS_JOB')<boolean>();
export const setId = createAction('SET_ID')<string>();
export const clearDataset = createAction('CLEAR_DATASET')();
export const initializeLabeling = createAsyncAction(
    "INITIALIZE_LABELING_REQUEST",
    "INITIALIZE_LABELING_SUCCESS",
    "INITIALIZE_LABELING_FAILURE"
  )<{ grainId: string }, void, Error>();

export const getJobDetails = createAsyncAction(
  "GET_JOB_DETAILS_REQUEST",
  "GET_JOB_DETAILS_SUCCESS",
  "GET_JOB_DETAILS_FAILURE"
)<{datasetId: any}, any, Error>();


  export const setIsExistingDataset = createAction('SET_IS_EXISTING_DATASET')<boolean>();
  export const fetchDatasetInfo = createAsyncAction(
    "FETCH_DATASET_INFO_REQUEST",
    "FETCH_DATASET_INFO_SUCCESS",
    "FETCH_DATASET_INFO_FAILURE"
  )<void, { datasetID: string, status: string }[], Error>();

export const fetchDatasetDetails = createAsyncAction(
  'FETCH_DATASET_DETAILS_REQUEST',
  'FETCH_DATASET_DETAILS_SUCCESS',
  'FETCH_DATASET_DETAILS_FAILURE'
)<FetchDatasetDetailsRequest, any, Error>();

export const updateImageLabels = createAsyncAction(
  'UPDATE_IMAGE_LABELS_REQUEST',
  'UPDATE_IMAGE_LABELS_SUCCESS',
  'UPDATE_IMAGE_LABELS_FAILURE'
)<UpdateImageLabelsRequest,UpdateImageLabelsSuccess,UpdateImageLabelsFailure>();

export const updateLabelingStatus = createAction('UPDATE_LABELING_STATUS')<LabelingStatus>();


export const saveAsDraft = createAsyncAction(
  "SAVE_AS_DRAFT_REQUEST",
  "SAVE_AS_DRAFT_SUCCESS",
  "SAVE_AS_DRAFT_FAILURE"
)<onSubmitLabelingRequest,void,onSubmitLabelingFailure>();

export const completeLabeling = createAsyncAction(
  "COMPLETE_LABELING_REQUEST",
  "COMPLETE_LABELING_SUCCESS",
  "COMPLETE_LABELING_FAILURE"
)<onSubmitLabelingRequest,void,onSubmitLabelingFailure>();